<script>
import BVInput from "@/components/BVForm/BVInput.vue";

export default {
  components: {
    BVInput,
  },
  props: {
    schema: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    hideFooter: { type: Boolean, default: false },
    observer: { type: Array, default: () => [] },
    injectData: { type: Object, default: () => {} },
    path: { type: String, default: "" },
    callBackSubmit: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      data: {},
      loader: false,
    };
  },
  computed: {
    getMethod() {
      return this.method.toUpperCase();
    },
    isDisabled() {
      if (this.disabled) {
        return "disabled";
      } else {
        return "";
      }
    },
  },
  methods: {
    async onSubmit() {
      this.schema.forEach((item) => {
        this.data[item.name] = item.value;
      });
      this.data = { ...this.data, ...this.injectData };

      await this.callBackSubmit(this);
    },
    isObserver(name) {
      return this.observer.includes(name);
    },
    watcher(item) {
      if (this.isObserver(item.name)) {
        this.$emit("watcher", item);
      }
      this.schema[item.idx].value = item.value;
    },
  },
};
</script>

<template>
  <form @submit.prevent="onSubmit" class="mt-2 mb-3">
    <slot name="header">
      <slot name="title">
        <p v-if="title" class="h4">
          <Strong>{{ title }}</Strong>
        </p>
      </slot>
    </slot>
    <div class="grid-form">
      <BVInput
        v-for="(input, idx) in schema"
        :key="input.name"
        :name="input.name"
        :observer="isObserver(input.name)"
        :type="input.type"
        :label="input.label"
        :value="input.value"
        :options="input.options"
        :validations="input.validations"
        :tooltip="input.tooltip"
        :state="input.state + isDisabled"
        :idx="idx"
        :placeholder="input.placeholder"
        :errorMessages="input.errorMessages"
        :help="input.help"
        @input="watcher"
      >
      </BVInput>
      <slot name="otherInputs"> </slot>
    </div>
    <slot name="warning" :data="data"></slot>
    <div
      v-if="!hideFooter"
      class="d-flex flex-row-reverse pt-3"
      style="width: 100%; gap: 5px"
    >
      <slot name="submit" :data="data">
        <vs-button
          to=""
          animation-type="vertical"
          success
          :disabled="loader || disabled"
        >
          <template #animate> <i class="fas fa-save"></i></template>
          <span v-if="loader">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else>
            <slot name="text-submit"> Guardar </slot>
          </span>
        </vs-button>
      </slot>
      <slot name="otherBtns"></slot>
    </div>
  </form>
</template>

<style scoped>
.grid-form {
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(480px, 100%), 1fr));
  grid-auto-flow: dense;
}
</style>
