<script>
export default {
    name: "ItemList",
    props: ["title", "value"],
    computed: {
        mappedValue() {
            if ( this.value == '0001-01-01T00:00:00') return 'No hay información'
            const regex1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+$/;
            const regex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
            
            if (regex1.test(this.value) || regex2.test(this.value)) {
                console.log(this.value)
                const fecha = new Date(this.value);
                return this.$store.getters.useMoment(fecha)
            } else {

                return this.value;
            }
        }
    }
}
</script>
<template>
    <div class="mb-2">
        <p class="titulo">{{ title }}</p>
        <p class="descripcion">
            <template v-if="value">
                {{ mappedValue }}
            </template>
            <template v-else>
                <i>No hay información</i>
            </template>
        </p>
    </div>
</template>
<style scoped>
.titulo {
    font-weight: bold;
    color: #2e2c2e;
    margin-bottom: 5px;
}

.descripcion {
    color: #524f52;
    margin: 0;
}

.dark .titulo {
    font-weight: bold;
    color: #f5f5f5;
    margin-bottom: 5px;
}

.dark .descripcion {
    color: #ddd;
    margin: 0;
}
</style>